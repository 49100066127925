.reviews-component-section {
  background-color: #087852;
  color: white;
  padding: 50px 20px;
  text-align: center;
  /* transform: translateY(-80%);  */
}

.reviews-component-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
}

.reviews-component-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
}

.reviews-component-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.reviews-component-card {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  text-align: center;
  transition: transform 0.5s ease;
}

.reviews-component-name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.reviews-component-text {
  font-size: 16px;
  margin-bottom: 15px;
}

.reviews-component-stars {
  color: #ffd700;
  font-size: 32px;
}

.reviews-component-arrow {
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 2;
}

.reviews-component-arrow:hover {
  color: #ffd700;
}

.reviews-component-left-arrow {
  margin-right: 10px;
}

.reviews-component-right-arrow {
  margin-left: 10px;
}
