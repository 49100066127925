.hero {
  width: 100%;
  height: 600px;
  background-image: url("../../images/Hero.jpeg");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}
