.coach-hire-banner {
  position: relative;
  width: 80%; /* Constrain the width */
  margin: 0 auto; /* Center the section */
  padding: 20px; /* Add equal padding on all sides */
  display: flex;
  justify-content: space-between; /* Space out left and right content */
  align-items: center; /* Vertically align content */
  padding-bottom: 10vh;
  margin-top: 400px; /* Add margin to create spacing from the above component */
}

.banner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Utilize the full width of the parent */
}

.banner-left {
  width: 45%; /* Make the left section slightly narrower */
  margin-right: 5%; /* Space between left and right sections */
}

.banner-title {
  font-size: 46px;
  font-weight: 700;
  color: var(--header-text-color); /* Black */
  margin-bottom: 10%;
}

.banner-subtitle {
  font-size: 28px;
  font-weight: 600;
  color: var(--accent-color); /* Green */
  margin: 15px 0;
  margin-bottom: 10%;
}

.banner-description {
  font-size: 16px;
  color: var(--header-description-text-color); /* Dark grey */
  margin-bottom: 20px;
}

.banner-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--accent-color); /* Razer green */
  color: var(--bg-color); /* White */
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 15px;
}

.banner-right {
  width: 50%; /* Ensure the right section fits well */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-image {
  width: 60%; /* Reduce the size of the phone image */
  max-height: 600px; /* Constrain the maximum height */
}

.review-box {
  position: absolute;
  bottom: -30px; /* Overlap slightly */
  left: 10%; /* Position closer to the center of the phone */
  background-color: var(--accent-color); /* Green background */
  color: var(--bg-color); /* White text */
  border: 2px solid var(--dark-grey);
  padding: 20px;
  border-radius: 10px;
  width: 260px; /* Adjust width for better balance */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.review-name {
  font-size: 16px;
  font-weight: 700;
}

.review-stars {
  color: var(--accent-color-razr); /* Razer green */
  margin: 8px 0;
}

.review-text {
  font-size: 14px;
  line-height: 1.4;
}

@media screen and (max-width: 500px) {
  .coach-hire-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; /* Full width */
    height: 90vh; /* Fill 90% of the viewport height */
    padding: 0 15px; /* Add horizontal padding */
    box-sizing: border-box; /* Include padding in width calculation */
    overflow: hidden; /* Prevent content overflow */
    margin-top: 1100px; /* Add margin to create spacing from the above component */
  }

  .banner-wrapper {
    position: relative; /* Allow positioning of child elements */
    display: flex;
    flex-direction: column; /* Stack content vertically */
    align-items: center;
    justify-content: center;
    width: 100%; /* Full width */
    height: 100%; /* Utilize full height */
  }

  .banner-left {
    width: 100%; /* Full width for text content */
    text-align: center;
    margin-bottom: 20px; /* Add spacing */
  }

  .banner-title {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .banner-subtitle {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .banner-description {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .banner-button {
    font-size: 14px;
    padding: 10px 20px;
    margin-top: 10px;
  }

  .banner-right {
    position: relative; /* Establish a positioning context for the image and review box */
    width: 100%; /* Full width */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner-image {
    width: 80%; /* Scale down the image */
    max-width: 300px; /* Constrain image width */
    height: auto; /* Maintain aspect ratio */
  }

  .review-box {
    position: absolute; /* Layer the review box over the image */
    bottom: 5%; /* Position 40% from the bottom of the phone */
    left: 40%; /* Start at 50% of the image width */
    width: 60%; /* Adjust width for a smaller size */
    max-width: 180px; /* Limit the maximum width */
    padding: 15px;
    background-color: var(--accent-color); /* Green background */
    color: var(--bg-color); /* White text */
    border-radius: 8px;
    border: 2px solid var(--dark-grey);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow for depth */
    text-align: center; /* Center-align content inside the box */
  }

  .review-name {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .review-stars {
    margin: 5px 0;
  }

  .review-text {
    font-size: 12px;
    line-height: 1.4;
  }
}

@media screen and (min-width: 1440px) {
  .banner-image {
    width: 50%; /* Reduce the width for wide monitors */
    max-height: 500px; /* Adjust the maximum height */
  }
}

@media screen and (min-width: 1920px) {
  .banner-image {
    width: 30%; /* Further reduce the width for ultrawide monitors */
    max-height: 400px; /* Further constrain the maximum height */
  }
}
