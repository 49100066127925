/* ===================================
   DESKTOP STYLES
=================================== */
.vehicles-section {
  background-color: var(--light-grey);
  text-align: center;
  border-top: 2px solid var(--accent-color);
}

.vehicles-header {
  padding: 30px; /* Add padding for the header content */
}

.vehicles-header h3,
.vehicles-header h2,
.vehicles-header p {
  margin: 0 auto 15px auto; /* Add consistent spacing between header elements */
}

.vehicles-header h3 {
  color: var(--accent-color);
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.vehicles-header h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: var(--header-text-color);
}

.vehicles-header p {
  font-size: 1rem;
  margin-bottom: 30px;
  color: var(--header-description-text-color);
}

.vehicles-content {
  padding: 20px 0; /* Add padding around the content area */
}

.vehicles-grid {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin: 20px 0; /* Add vertical spacing for grid */
}

.vehicles-carousel {
  display: none; /* Hide carousel on desktop */
}

/* ===================================
   VEHICLE CARD STYLES
=================================== */
.vehicle-card {
  background-color: var(--bg-color);
  border: 1px solid var(--dark-grey);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.vehicle-card img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 4px;
}

.vehicle-card h4 {
  color: var(--accent-color);
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.vehicle-card p {
  font-size: 0.9rem;
  color: var(--secondary-text);
}

.vehicles-button-container {
  margin-top: 20px;
}

.vehicles-button {
  background-color: var(--accent-color);
  color: var(--bg-color);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.vehicles-button:hover {
  background-color: var(--accent-color-dark);
  transform: scale(1.05);
}

/* ===================================
   VEHICLES SWIPER PAGINATION STYLES
=================================== */
.vehicles-swiper-pagination {
  margin-top: 20px; /* Add spacing between cards and bullets */
  text-align: center;
}

.vehicles-swiper-pagination .swiper-pagination-bullet {
  background-color: var(--dark-grey); /* Style default bullets */
  opacity: 0.6;
  width: 12px;
  height: 12px;
  margin: 0 5px;
}

.vehicles-swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color); /* Highlight active bullet */
  opacity: 1;
}

/* ===================================
   MOBILE STYLES
=================================== */
@media screen and (max-width: 768px) {
  .vehicles-grid {
    display: none; /* Hide grid on mobile */
  }

  .vehicles-carousel {
    display: block; /* Show carousel on mobile */
  }

  .vehicle-card {
    padding: 10px; /* Adjust card padding */
    margin: 0 auto 15px auto; /* Add spacing between cards */
  }

  .vehicles-button {
    width: 100%; /* Full-width button for mobile */
  }
}

@media screen and (max-width: 500px) {
  .vehicle-card {
    width: 80%; /* Full width on very small screens */
    height: 350px; /* Further reduce height */
    padding: 8px; /* Adjust card padding further */
    padding-bottom: 10px;
  }

  .vehicles-content {
    padding: 0; /* Remove padding around the content area */
  }

  .vehicles-header p {
    font-size: 1rem;
    margin-bottom: 0px;
    color: var(--header-description-text-color);
  }

  .vehicles-swiper-pagination {
    margin-top: 30px; /* Increase spacing above pagination bullets */
  }
}
