.coach-near-you-section {
  background-color: var(--light-grey); /* Neutral background for readability */
  padding: 80px 40px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Add depth */
  border-top: 2px solid var(--accent-color); /* Add a border for subtle separation */
}

.coach-near-you-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--accent-color); /* Use green for a professional vibe */
  margin-bottom: 20px;
  text-transform: capitalize;
}

.coach-near-you-content p {
  font-size: 1.1rem;
  color: var(--secondary-text); /* Softer grey for paragraph text */
  line-height: 1.7;
  margin-bottom: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.coach-near-you-content p strong {
  color: var(--accent-color); /* Highlighted words in bright green */
}

.coach-near-you-button {
  background-color: var(--accent-color); /* Bright green for CTA */
  color: var(--bg-color); /* White text for contrast */
  border: none;
  border-radius: 8px;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.coach-near-you-button:hover {
  background-color: var(--accent-color); /* Darker green on hover */
  transform: scale(1.05); /* Slight scale effect */
}

.coach-near-you-button-container {
  margin-top: 30px;
}
