/* ====================
   General Section Styles
==================== */
.service-cards {
  text-align: center;
  background-color: var(--light-grey);
  padding: 50px 20px; /* Default padding for desktop */
  border-top: 2px solid var(--accent-color);
  overflow: hidden; /* Prevent side scroll caused by internal elements */
}

.section-tagline {
  color: var(--accent-color);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.section-title {
  font-size: 2rem;
  font-weight: 700;
  color: var(--header-text-color);
  margin-bottom: 15px;
}

.section-description {
  font-size: 16px;
  color: var(--header-description-text-color);
  margin-bottom: 40px;
}

.desktop-card-image,
.mobile-card-image {
  width: 100%; /* Full width of the container */
  height: 200px; /* Fixed height for uniformity */
  object-fit: cover; /* Ensure the image maintains aspect ratio and fills the area */
  border-radius: 4px; /* Keep the rounded corners consistent */
}

/* ====================
   Desktop Grid Layout
==================== */
.desktop-cards-container {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin: 20px 0;
}

.desktop-service-card {
  background-color: var(--bg-color); /* Same as vehicles section */
  border: 1px solid var(--dark-grey); /* Apply similar border */
  border-radius: 8px; /* Match vehicle card border */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adjusted shadow */
  padding: 20px;
  text-align: center;
  width: 20rem; /* Equal card widths */
  min-width: 280px;
  display: flex;
  flex-direction: column; /* Maintain consistent structure */
  justify-content: space-between; /* Distribute content evenly */
  height: 100%; /* Make cards flexible based on tallest content */
}

.desktop-card-image {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 4px; /* Apply border radius to image */
  object-fit: cover;
}

.desktop-card-title {
  color: var(--accent-color); /* Match vehicle card title */
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 700;
}

.desktop-card-description {
  font-size: 0.9rem; /* Match description styling */
  color: var(--secondary-text); /* Match text color */
  margin-bottom: 20px;
  font-weight: 600;
}

.desktop-card-button {
  display: inline-block;
  padding: 10px 20px; /* Adjusted padding to match */
  background-color: var(--accent-color);
  color: var(--bg-color);
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s; /* Add hover effect */
}

.desktop-card-button:hover {
  background-color: var(--accent-color-dark);
  transform: scale(1.05); /* Slight scale on hover */
}

/* ====================
   Mobile Carousel Styles
==================== */
.mobile-cards-container {
  display: none; /* Ensure the carousel is visible on mobile */
  padding: 0; /* Remove padding around the carousel */
  margin: 0 auto; /* Center align the carousel container */
}

.mobile-modern-card {
  background-color: var(--bg-color); /* Same as vehicles section */
  border: 1px solid var(--dark-grey); /* Apply similar border */
  border-radius: 8px; /* Match vehicle card border */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adjusted shadow */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto; /* Center the card horizontally */
  width: 90%; /* Adjust card width for smaller screens */
  max-width: 350px; /* Prevent cards from growing too wide */
  height: 450px; /* Fixed height for all cards */
}

.mobile-card-header {
  height: 200px; /* Adjust image container height */
  overflow: hidden;
}

.mobile-card-image {
  width: 100%;
  margin-bottom: 15px;
  object-fit: cover;
}

.mobile-card-content {
  padding: 20px;
  text-align: center; /* Match vehicle card content alignment */
  flex-grow: 1; /* Ensure content fills space evenly */
}

.mobile-card-title {
  color: var(--accent-color); /* Match vehicle card title */
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 700;
}

.mobile-card-description {
  font-size: 0.9rem; /* Match description styling */
  color: var(--secondary-text); /* Match text color */
  margin-bottom: 20px;
  font-weight: 600;
}

.mobile-card-footer {
  padding: 20px;
  text-align: center;
}

.mobile-card-button {
  display: inline-block;
  padding: 10px 20px; /* Adjusted padding to match */
  background-color: var(--accent-color);
  color: var(--bg-color);
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s; /* Add hover effect */
}

.mobile-card-button:hover {
  background-color: var(--accent-color);
  transform: scale(1.05); /* Slight scale on hover */
}

.mobile-card-button:active {
  background-color: var(--accent-color);
}

/* ====================
   Custom Pagination Styles
==================== */
.custom-swiper-pagination {
  margin-top: 30px; /* Add spacing between the card and pagination bullets */
  text-align: center; /* Center the bullets */
}

.custom-swiper-pagination .swiper-pagination-bullet {
  background-color: var(--dark-grey); /* Style for default bullets */
  opacity: 0.6;
  width: 12px;
  height: 12px;
  margin: 0 5px;
}

.custom-swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color); /* Highlight active bullet */
  opacity: 1;
}

/* ====================
   Responsive Adjustments
==================== */
@media (max-width: 768px) {
  .desktop-card-button {
    display: none;
  }
  .desktop-cards-container {
    display: none; /* Hide desktop grid on mobile */
    padding-bottom: 50px;
  }

  .mobile-cards-container {
    display: block; /* Show carousel on mobile */
    padding: 0; /* Remove padding around the carousel */
  }

  .service-cards {
    padding: 0; /* Remove section padding on mobile to avoid overflow */
  }

  .section-title,
  .section-description,
  .section-tagline {
    padding: 0 15px; /* Add padding only to text elements */
  }

  .mobile-modern-card {
    margin: 0 auto;
    width: 90%; /* Adjust card width for smaller screens */
    max-width: 350px; /* Prevent cards from growing too wide */
  }

  .mobile-card-content {
    padding: 15px; /* Adjust padding for content on mobile */
  }

  .mobile-card-footer {
    padding: 15px; /* Adjust footer padding */
  }

  .custom-swiper-pagination {
    margin-top: 30px; /* Increase spacing above pagination bullets */
  }
}
