/* ===================================
   GENERAL STYLING
=================================== */
.hero-wrapper {
  position: relative; /* Establish positioning context */
  width: 100%;
  height: 100%; /* Adjust height if necessary */
  overflow: visible;
}

.enquiry-section {
  position: absolute; /* Absolute position within the hero-wrapper */
  top: 90%; /* Center vertically within the hero */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the form */
  z-index: 10; /* Ensure it appears above the hero */
  width: 80%; /* Adjust width as needed */
}

/* ===================================
   ENQUIRY FORM CONTENT
=================================== */
.enquiry-content {
  background-color: rgba(255, 255, 255, 1);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid var(--accent-color);
}

.enquiry-content h1 {
  margin-bottom: 10px;
  font-size: 24px;
  color: var(--accent-color);
}

.enquiry-content p {
  margin-bottom: 20px;
}

/* Journey Type Buttons Container */
.journey-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Add spacing below buttons */
  gap: 15px; /* Spacing between buttons */
}

/* Toggle Button Styles */
.journey-button {
  padding: 12px 25px; /* Comfortable padding for readability */
  font-size: 16px; /* Modern font size */
  font-weight: bold;
  border: 2px solid var(--accent-color); /* Subtle border for distinction */
  background-color: #f9f9f9; /* Neutral background */
  color: var(--accent-color); /* Accent color for text */
  border-radius: 25px; /* Fully rounded corners */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transitions for hover and active states */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Hover State */
.journey-button:hover {
  background-color: var(--accent-color); /* Accent color on hover */
  color: #fff; /* Text changes to white */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

/* Active/Selected Button */
.journey-button.active {
  background-color: var(
    --accent-color
  ); /* Filled background for active state */
  color: #fff; /* White text for contrast */
  border-color: var(--accent-color); /* Match border with background */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Deeper shadow */
}

/* Focus State */
.journey-button:focus {
  outline: none;
  box-shadow: 0 0 6px rgba(1, 238, 250, 0.8); /* Highlighted outline for accessibility */
}
/* ===================================
   FORM STRUCTURE
=================================== */
.enquiry-form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px; /* Adjusted for better spacing */
  padding-bottom: 80px;
}

.form-row {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-group input,
.form-group select {
  font-size: 16px; /* Uniform font size */
  padding: 12px 15px; /* Better padding for modern appearance */
  border: 1px solid rgba(0, 0, 0, 0.15); /* Lighter border for cleaner look */
  border-radius: 8px; /* Rounded corners for modern style */
  color: #333; /* Darker text color for readability */
  background-color: #f9f9f9; /* Subtle background for inputs */
  box-sizing: border-box;
  outline: none;
  width: 100%;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 6px rgba(1, 238, 250, 0.5); /* Highlighted on focus */
  background-color: #fff; /* White background on focus */
}

/* Placeholder Styling */
input::placeholder,
select::placeholder {
  color: rgba(0, 0, 0, 0.4); /* Softer placeholder text */
  font-style: italic;
}

/* Via Points */
.via-point-row .form-group input {
  width: 100%;
  margin-bottom: 10px;
}

/* Row 1 Specific Styling */
.form-group.pickup-point {
  flex: 5;
}

.form-group.date-wrapper {
  flex: 3;
}

.form-group.time {
  flex: 2;
}

/* ===================================
   VIA POINT BUTTON STYLING
=================================== */
.via-point-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Centered vertically */
  gap: 10px;
  top: -20px; /* Adjusted to visually align between fields */
}

.via-point-container .via-point-button {
  background-color: var(--accent-color);
  color: #fff;
  border: none;
  font-size: 16px;
  border-radius: 25px; /* Rounded corners for button */
  cursor: pointer;
  padding: 10px 20px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.via-point-container .via-point-button:hover {
  background-color: var(--accent-color-razr);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Add subtle shadow on hover */
}

/* ===================================
   DESTINATION STYLING
=================================== */
.form-row.destination {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.form-group.destination {
  flex: 1;
  text-align: left;
}

.form-row.destination.return-trip .form-group.destination {
  flex: 5;
}

.form-row.destination.return-trip .form-group.return-date {
  flex: 3;
}

.form-row.destination.return-trip .form-group.return-time {
  flex: 2;
}

/* ===================================
   ROW 4 AND ROW 5
=================================== */
.additional-rows {
  overflow: hidden;
  max-height: 0;
  opacity: 1;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

/* Row 4 */
.form-row .form-group select,
.form-row .form-group input[type="number"] {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
}

/* Row 5 */
.form-row .form-group input[type="text"],
.form-row .form-group select {
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
}

/* ===================================
   BUTTON STYLING
=================================== */
.submit-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 12px 25px;
  width: auto;
  height: 50px;
  background: linear-gradient(135deg, var(--accent-color), var(--submit-color));
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.submit-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.4);
}

/* ===================================
   SUBMIT SECTION
=================================== */
.submit-section {
  display: flex;
  justify-content: space-between; /* Space between terms and submit button */
  align-items: center;
  margin-top: 20px;
  padding: 10px 0;
}

/* Terms and Conditions Styling */
.submit-section .form-checkbox-group {
  display: flex;
  flex-direction: column; /* Stack checkboxes vertically */
  align-items: flex-start; /* Align checkboxes to the left */
  gap: 10px; /* Spacing between checkboxes */
  flex: 1;
}

.submit-section .form-checkbox-group label {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
}

/* Submit Button Styling (unchanged from earlier redesign) */
.submit-section .submit-button {
  padding: 12px 25px;
  background: linear-gradient(135deg, var(--accent-color), var(--submit-color));
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.submit-section .submit-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.4);
}

/* Double Check Message Styling */
.submit-section .double-check-message {
  margin-top: 10px;
  font-size: 12px;
  color: #777;
  text-align: center;
}

/* ===================================
   MISCELLANEOUS STYLING
=================================== */
.section-separator {
  border: 0;
  border-top: 2px solid var(--accent-color);
  margin: 15px 0;
}

.checkbox-item label {
  font-size: 14px;
  line-height: 1.5;
}

/* ===================================
   REMOVE VIA POINT BUTTON
=================================== */
.remove-via-point-button {
  background-color: var(--accent-color); /* Match the main accent color */
  color: #fff; /* White text for contrast */
  border: none;
  border-radius: 50%; /* Fully rounded for a modern look */
  font-size: 16px; /* Clean font size */
  cursor: pointer;
  width: 35px; /* Small, uniform size */
  height: 35px; /* Matches width for a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s;
}

.remove-via-point-button:hover {
  background-color: var(
    --accent-color-razr
  ); /* Slightly different hover color */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Deeper shadow on hover */
  transform: scale(1.1); /* Slight enlargement for interactivity */
}

.remove-via-point-button:active {
  transform: scale(0.95); /* Shrinks slightly on click */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Softer shadow on click */
}

/* ===================================
   REQUIRED FOR RETURN CHECKBOX
=================================== */
.form-group.via-point-return-checkbox {
  display: flex;
  align-items: center; /* Align checkbox and label */
  gap: 10px; /* Space between checkbox and label */
  margin-top: 5px; /* Spacing above */
}

.form-group.via-point-return-checkbox input[type="checkbox"] {
  width: 20px; /* Larger checkbox for easier interaction */
  height: 20px; /* Match width to height */
  border: 1px solid rgba(0, 0, 0, 0.2); /* Light border for modern look */
  border-radius: 4px; /* Subtle rounding for a polished appearance */
  cursor: pointer; /* Show pointer on hover */
  transition: all 0.2s ease; /* Smooth hover and focus effects */
}

.form-group.via-point-return-checkbox input[type="checkbox"]:hover {
  border-color: var(--accent-color); /* Highlight border on hover */
  background-color: rgba(0, 0, 0, 0.05); /* Subtle hover background */
}

.form-group.via-point-return-checkbox input[type="checkbox"]:focus {
  outline: 2px solid var(--primary-color); /* Highlighted focus state */
}

.form-group.via-point-return-checkbox label {
  font-size: 14px; /* Modern font size */
  color: #333; /* Dark text for readability */
  cursor: pointer; /* Make the label clickable */
  transition: color 0.2s ease; /* Smooth transition for hover */
}

.form-group.via-point-return-checkbox label:hover {
  color: var(--accent-color); /* Slightly highlight text on hover */
}

/* ===================================
   TOGGLE SWITCH STYLING
=================================== */
.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between toggle and label */
}

.toggle-switch {
  position: relative;
  width: 50px;
  height: 25px;
  background-color: #ccc; /* Default grey background */
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-switch::before {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  background-color: #fff; /* Thumb color */
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.toggle-switch.active {
  background-color: #4caf50; /* Green when active */
}

.toggle-switch.active::before {
  transform: translateX(25px); /* Move the thumb to the right */
}

.toggle-label {
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .enquiry-section {
    position: absolute; /* Absolute position within the hero-wrapper */
    top: 140%; /* Center vertically within the hero */
    justify-content: center;
    z-index: 10; /* Ensure it appears above the hero */
    width: 90%; /* Adjust width as needed */
  }

  /* General Layout */
  .enquiry-content {
    padding: 20px; /* Comfortable padding */
    border-radius: 15px; /* Softer corners */
  }

  /* Journey Buttons */
  .journey-buttons {
    flex-direction: row; /* Keep buttons in one row */
    gap: 10px; /* Reduce gap for mobile */
  }

  .journey-button {
    flex: 1; /* Equal width for both buttons */
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 15px; /* Match overall style */
  }

  /* Form Rows */
  .form-row {
    flex-direction: column; /* Stack inputs vertically */
    gap: 20px; /* Larger gap for readability */
  }

  .form-group {
    width: 100%; /* Full width for inputs */
  }

  .form-group input,
  .form-group select {
    font-size: 15px; /* Slightly smaller for mobile */
    padding: 15px; /* Larger padding for touch areas */
    border-radius: 12px; /* Rounded corners for a modern feel */
    width: 100%; /* Ensure full width consistency */
    max-width: 100%; /* Prevent shrinking */
  }

  /* Dynamic Via Points */
  .via-point-row .form-group {
    width: 100%; /* Match via point input width to pickup point */
  }

  /* Customer Details */
  .contact-info .form-group {
    width: 100%; /* Full width for customer details */
    margin-bottom: 10px; /* Add space between rows for clarity */
  }

  .contact-info .form-group input {
    padding: 15px; /* Same padding for customer details */
    border-radius: 12px; /* Match the rounded style */
  }

  /* Submit Section */
  .submit-section {
    flex-direction: column; /* Stack terms and button */
    align-items: stretch; /* Full width alignment */
    gap: 20px; /* Space between terms and button */
    margin-top: 30px; /* Clear separation from the form */
  }

  .submit-section .form-checkbox-group {
    align-items: flex-start; /* Align checkboxes to the left */
    justify-content: center; /* Center horizontally */
    flex-direction: column; /* Stack toggles vertically */
    gap: 15px; /* Add spacing between toggles */
    width: 100%; /* Ensure full width */
  }

  .toggle-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between toggle and label */
    justify-content: flex-start; /* Align toggles to the left */
    width: 100%; /* Ensure consistent width for each toggle */
  }

  .toggle-switch {
    position: relative;
    width: 50px; /* Adjust width for mobile */
    height: 25px;
    background-color: #ccc; /* Default grey background */
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .toggle-switch::before {
    content: "";
    position: absolute;
    width: 21px;
    height: 21px;
    background-color: #fff; /* Thumb color */
    border-radius: 50%;
    top: 2px;
    left: 2px;
    transition: transform 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .toggle-switch.active {
    background-color: #4caf50; /* Green when active */
  }

  .toggle-switch.active::before {
    transform: translateX(25px); /* Move the thumb to the right */
  }

  .toggle-label {
    font-size: 14px; /* Adjust font size for mobile */
    color: #333;
    cursor: pointer;
    flex: 1; /* Ensure labels take available space */
    text-align: left; /* Align text to the left for readability */
  }

  /* Row 1 Specific Styling */
  .form-group.pickup-point,
  .form-group.date-wrapper,
  .form-group.time,
  .form-group.destination,
  .form-group.return-date,
  .form-group.return-time {
    width: 100%; /* Match width to pickup point */
    max-width: 100%; /* Ensure no shrinking */
  }

  /* Row 4 & Row 5 Specific Styling */
  .form-row .form-group select,
  .form-row .form-group input {
    width: 100%; /* Match width consistency for rows 4 and 5 */
  }

  /* Row 2 */
  .form-row.destination {
    flex-wrap: wrap; /* Allow elements to wrap in smaller screens */
  }

  .form-row.destination.return-trip .form-group.destination,
  .form-row.destination.return-trip .form-group.return-date,
  .form-row.destination.return-trip .form-group.return-time {
    width: 100%; /* Full width for return-trip elements */
  }
}
