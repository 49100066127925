:root {
  --bg-color: #ffffff; /* White */
  --text-color: #000000; /* Black */
  --accent-color: #087852; /* Green */
  --accent-color-razr: #47e10c; /* Razer Green */
  --submit-color: #a6f5a5; /* Lighter Green */
  --light-grey: #f0f0f0; /* Light Grey */
  --dark-grey: #a4a3a3; /*Dark Grey */
  --header-text-color: #494848;
  --header-description-text-color: #1d1d1b;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--light-grey);

  color: var(--text-color);
  font-family: Arial, sans-serif;
}

a {
  color: var(--accent-color);
  text-decoration: none;
}

.App {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  max-width: 100vw;
}

.App-content {
  padding-top: 60px; /* Offset for sticky header */
}
