.footer-section {
  background-color: #1b1b1b; /* Dark background */
  color: var(--light-grey); /* Light grey text for readability */
  padding: 50px 20px;
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); /* Flexible grid */
  gap: 40px; /* Even spacing between columns */
  text-align: left;
}

.footer-column {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Consistent spacing between elements */
}

.footer-title {
  font-size: 1.4rem; /* Larger for modern readability */
  font-weight: bold;
  color: var(--accent-color-razr); /* Bright green for headings */
  margin-bottom: 10px;
  text-transform: uppercase;
}

.footer-contact p {
  font-size: 1rem;
  line-height: 1.8; /* Improved readability */
  margin: 5px 0;
  color: var(--light-grey); /* Muted grey for contact info */
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  font-size: 1rem;
  color: var(--bg-color); /* White for links */
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: var(--accent-color-razr); /* Bright green on hover */
}

.footer-enquire-button {
  background-color: var(--accent-color-razr); /* Bright green */
  color: var(--bg-color); /* White text */
  border: none;
  border-radius: 30px;
  padding: 15px 20px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  align-self: flex-start;
}

.footer-enquire-button:hover {
  background-color: var(--accent-color); /* Slightly darker green */
  transform: scale(1.05);
}

.footer-socials {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.footer-socials a {
  font-size: 1.8rem; /* Larger icon size for emphasis */
  color: var(--bg-color); /* White icons */
  transition: transform 0.3s ease, color 0.3s ease;
}

.footer-socials a:hover {
  color: var(--accent-color-razr); /* Bright green on hover */
  transform: scale(1.2); /* Subtle zoom effect */
}

.footer-bottom {
  margin-top: 40px;
  border-top: 1px solid var(--dark-grey); /* Subtle divider */
  padding-top: 20px;
  text-align: center;
  font-size: 0.9rem; /* Slightly smaller text */
  color: var(--dark-grey); /* Muted grey for the footer bottom */
  line-height: 1.6;
}

.footer-design {
  text-align: center;
  margin-top: 20px;
  font-size: 0.7rem;
  color: var(--dark-grey); /* Muted grey for subtle emphasis */
}

.footer-design a {
  color: var(--accent-color); /* Accent colour for the link */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.footer-design a:hover {
  color: var(--accent-color-razr); /* Highlighted on hover */
}

@media (max-width: 768px) {
  .footer-container {
    grid-template-columns: 1fr; /* Single column for mobile */
    gap: 20px;
  }

  .footer-enquire-button {
    align-self: center; /* Center the button on mobile */
  }

  .footer-socials {
    justify-content: center; /* Center icons on mobile */
  }

  .footer-bottom {
    font-size: 0.8rem; /* Slightly smaller for mobile */
  }
}
