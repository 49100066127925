.features-section {
  background-color: var(--light-grey); /* Dark background */
  color: var(--accent-color);
  padding: 60px 20px;
  text-align: center;
  border-top: 2px solid var(--accent-color); /* Add a border for subtle separation */
}

.features-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 40px; /* Space between cards */
  justify-items: center; /* Center align each card */
  align-items: start; /* Align items to the start of the grid */
}

.feature-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  text-align: center;
  padding: 30px 20px;
  max-width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid var(--accent-color);
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.feature-icon {
  font-size: 60px; /* Larger icon size */
  color: var(--accent-color); /* Use the theme's accent color */
  margin-bottom: 20px;
}

.feature-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 14px;
  line-height: 1.6;
}

@media (max-width: 1024px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns for tablets */
  }
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr; /* Single column for mobile devices */
  }
}
