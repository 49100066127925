.header {
  background-color: var(
    --light-grey
  ); /* Light grey background for the header */
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0; /* Ensure no extra padding */
  margin: 0; /* Remove any margin */
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 10px 20px; /* Maintain padding for compact layout */
  height: 100px; /* Match height with content */
}

/* Contact Section (Left) */
.header-contact {
  position: absolute;
  top: 10px;
  left: 10%; /* Position 20% from the left border */
  display: flex;
  flex-direction: column; /* Stack the buttons vertically */
  align-items: flex-start; /* Align buttons to the left */
  gap: 10px; /* Add spacing between the buttons */
}

.header-phone,
.header-work-with-us {
  display: flex; /* Align content inside */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  width: 120px; /* Fixed width for equal sizing */
  text-decoration: none; /* Remove underline for links */
  color: #fff; /* White text for visibility */
  background-color: var(--accent-color); /* Accent color for the buttons */
  font-weight: bold;
  font-size: 14px;
  border-radius: 50px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  transition: background-color 0.3s ease;
}

/* Social Media Section (Right) */
.header-socials {
  position: absolute;
  top: 10px;
  right: 10%; /* Position 20% from the right border */
  display: flex;
  gap: 10px; /* Spacing between social icons */
}

.header-phone {
  display: inline-block;
  text-decoration: none;
  color: #fff; /* White text for visibility */
  background-color: var(--accent-color); /* Accent color for the circle */
  font-weight: bold;
  font-size: 14px;
  padding: 10px 20px; /* Space inside the circle */
  border-radius: 50px; /* Makes it circular */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
  transition: background-color 0.3s ease;
}

.header-phone:hover {
  background-color: var(--submit-color); /* Lighter green on hover */
}

/* Desktop Logo Section */
.header-logo {
  flex: 1;
  display: flex;
  justify-content: center; /* Center align the logo */
  align-items: center; /* Center vertically */
  margin: 0 auto;
  max-height: 100%; /* Prevent cropping by restricting max height */
  overflow: visible; /* Ensure content overflows if necessary */
}

.header-logo img {
  height: auto; /* Maintain aspect ratio */
  max-height: 100px; /* Restrict height to fit the header */
  width: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure it scales properly within its container */
}

.social-icon {
  color: var(--text-color); /* Use text color */
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: var(--accent-color); /* Use accent color on hover */
}

.header-work-with-us {
  display: inline-block;
  text-decoration: none;
  color: #fff; /* White text for visibility */
  background-color: var(--accent-color); /* Accent color for the button */
  font-weight: bold;
  font-size: 14px;
  padding: 10px 20px; /* Space inside the button */
  border-radius: 50px; /* Rounded corners */
  margin-top: 10px; /* Space between the phone button and this button */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  transition: background-color 0.3s ease;
}

.header-work-with-us:hover {
  background-color: var(--submit-color); /* Lighter green on hover */
}
/* Mobile Contact Section */
.header-contact-mobile {
  display: none;
}
/* ==========================================
   MOBILE STYLING (Separate Mobile Icons)
========================================== */
@media screen and (max-width: 768px) {
  .header-container {
    padding: 10px;
    height: auto; /* Allow container height to adjust based on content */
  }

  /* Hide Desktop Contact Section */
  .header-contact {
    display: none;
  }

  /* Mobile Contact Section */
  .header-contact-mobile {
    position: absolute;
    top: 10px;
    left: 10px; /* Align to the left side */
    display: flex;
    gap: 10px;
  }

  .mobile-phone-icon,
  .mobile-handshake-icon {
    font-size: 16px; /* Adjust icon size for mobile */
    color: var(--accent-color);
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .mobile-phone-icon:hover,
  .mobile-handshake-icon:hover {
    color: var(--submit-color);
  }

  /* Social Media Icons */
  .header-socials {
    top: 10px;
    right: 10px; /* Align to the right side */
    display: flex;
    gap: 10px;
  }

  /* Adjust Logo for Mobile */
  .header-logo {
    display: flex; /* Ensure the logo is displayed as flex */
    justify-content: center; /* Center align the logo */
    align-items: center; /* Center align vertically */
    margin: 0 auto; /* Center align horizontally */
    width: 100%; /* Use full width for responsiveness */
    height: auto; /* Automatically adjust height */
  }

  .header-logo img {
    max-width: 100%; /* Ensure the logo scales within the container */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the logo fits inside the container */
    padding: 10px; /* Add padding to avoid cutting off */
  }
}
